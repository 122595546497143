import service from '@/middleware'

const eventApi = {
  async getEventsFilterStructure () {
    let result = await service.http.get('/api/event/all-filter')
    return result
  },
  async getEventsByThingsIds (ids) {
    let result = await service.http.post('/api/event/by-things', { thingIds: ids })
    return result.data
  },
  async getInternalEvents () {
    let result = await service.http.get('/api/event/internal-events')
    return result.data
  }
}

export default eventApi
