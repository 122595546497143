import runningHoursReportApi from '@/api/runningHoursReport.api'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { getDateTimeRange, dateSortFunction } from '@/tools/functions'
import { geoReferenceApi, cancelGeoData, cancelPOI } from '@/api/geoReference.api'
import i18n from '@/i18n'
import runningHoursReportService from '../../../business/runningHoursReportService'

export default {
  name: 'RunningHoursDetailReportComponent',
  components: {
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'id',
    tableComponentButtons: [],
    loadingSpinner: true,
    loadingTable: true,
    disableRefreshTable: true,
    timeStampFrom: undefined,
    timeStampTo: undefined,
    geoDataIndex: -1,
    poiDataIndex: -1,
    maxRadius: 20,
    requestCountGeoData: 0,
    requestCountPOI: 0,
    // se activa cuando las referencias fueron resueltas
    referenceResolved: false,
    // se activa cuando las ubicaciones fueron resueltas
    ubicationResolved: false
  }),
  computed: {
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    }),
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  created () {
    // se agregan los botones para obtener las georeferencias y puntos de interes a la tabla
    this.tableComponentButtons.push(
      {
        id: 'get-geo-data',
        tooltip: i18n.t('poi.loadLocations'),
        icon: 'explore',
        action: this.fireGeoDataRequests.bind(this),
        selectedData: false
      })
    this.tableComponentButtons.push(
      {
        id: 'get-POI',
        tooltip: i18n.t('poi.load'),
        icon: 'push_pin',
        action: this.firePOIRequests.bind(this),
        selectedData: false
      })
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    if (this.selectedItem) {
      this.getData(this.selectedItem)
    }
  },
  beforeDestroy () {
    this.closeReportDefer()
    this.resetEntitySelector()
    clearInterval(this.intervalId)
    this.intervalId = null
    // Cancelar las request pendientes
    if (this.requestCountGeoData > 0) {
      cancelGeoData('cancel geo-data requests')
    }
    if (this.requestCountPOI > 0) {
      cancelPOI('cancel poi requests')
    }
    // limpiar el timeout
    clearTimeout(this.$_debounceTimeoutId)
    this.$_debounceTimeoutId = null
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    ...mapMutations('reportDefer', {
      showSnackbar: 'showSnackbar',
      closeSnackbar: 'closeSnackbar',
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    refreshTable () {
      if (this.selectedItem) {
        this.getData(this.selectedItem, true)
      }
    },
    resetGeoData () {
      if (this.requestCountGeoData > 0) {
        cancelGeoData('cancel geo-data requests')
      }
      if (this.requestCountPOI > 0) {
        cancelPOI('cancel poi requests')
      }
      this.geoDataIndex = -1
      this.poiDataIndex = -1
      this.maxRadius = 20
      this.requestCountGeoData = 0
      this.requestCountPOI = 0
      // limpiar el timeout
      clearTimeout(this.$_debounceTimeoutId)
      this.$_debounceTimeoutId = null
      // si ya se han resuelto las referencias, vuelvo a ejecutar el proceso
      if (this.referenceResolved) {
        this.firePOIRequests()
      }
      // si ya se han resuelto las ubicaciones, vuelvo a ejecutar el proceso
      if (this.ubicationResolved) {
        this.fireGeoDataRequests()
      }
    },
    getData (data, refresh = false) {
      const that = this
      if (data.autoReport) {
        const conversion = getDateTimeRange(data.dateTimeRange, data.customDateTimeRangeType)
        that.timeStampFrom = conversion.tFrom
        that.timeStampTo = conversion.tTo
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        that.loadingTable = true
        that.disableRefreshTable = false
        runningHoursReportApi.getDetail(data.thingId, that.timeStampFrom, that.timeStampTo, data.events).then(async response => {
          const processedData = await runningHoursReportService.processSummaryDataDetail(response.data)
          if (!refresh) {
            this.setTableFilterRules(processedData.headers)
            this.tableHeaders.cleanAndUpdate(processedData.headers)
            this.setSortFunctions()
          }
          this.tableData.cleanAndUpdate(processedData.data)
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          that.loadingTable = false
          this.resetGeoData()
        })
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            const conversion = getDateTimeRange(data.dateTimeRange, data.customDateTimeRangeType)
            that.timeStampFrom = conversion.tFrom
            that.timeStampTo = conversion.tTo
            if (that.loadingSpinner) that.commitVisible({ visible: true })
            that.loadingTable = true
            runningHoursReportApi.getDetail(data.thingId, that.timeStampFrom, that.timeStampTo, data.events).then(async response => {
              const processedData = await runningHoursReportService.processSummaryDataDetail(response.data)
              that.tableData.cleanAndUpdate(processedData.data)
              if (that.loadingSpinner) that.commitVisible({ visible: false })
              that.loadingTable = false
              that.resetGeoData()
            })
          },
          data.autoReportMinutes * 60 * 1000)
      } else {
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        that.loadingTable = true
        runningHoursReportApi.getDetail(data.thingId, data.from, data.to, data.events).then(async response => {
          const processedData = await runningHoursReportService.processSummaryDataDetail(response.data)
          this.setTableFilterRules(processedData.headers)
          this.tableHeaders.cleanAndUpdate(processedData.headers)
          this.setSortFunctions()
          this.tableData.cleanAndUpdate(processedData.data)
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          that.loadingTable = false
          this.resetGeoData()
        })
      }
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    clickRow () {
    },
    setSortFunctions () {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'date').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
    },
    fireGeoDataRequests () {
      const header = this.tableHeaders.find(h => h.value === 'address')
      if (header) {
        header.spinner = true
        header.cellConfig = { spinnerColor: '#03A9F4' }
        header.selected = true
      }
      this.getGeoData()
      this.ubicationResolved = true
    },
    firePOIRequests () {
      const header = this.tableHeaders.find(h => h.value === 'reference')
      if (header) {
        header.spinner = true
        header.cellConfig = { spinnerColor: '#03A9F4' }
        header.selected = true
      }
      this.getPOI()
      this.referenceResolved = true
    },
    getGeoData () {
      this.geoDataIndex++
      if (this.geoDataIndex < this.tableData.length) {
        if (!this.tableData[this.geoDataIndex].address) {
          this.geoRequest(this.tableData[this.geoDataIndex], this.geoDataIndex)
        } else {
          this.getGeoData()
        }
      }
    },
    getPOI () {
      this.poiDataIndex++
      if (this.poiDataIndex < this.tableData.length) {
        if (!this.tableData[this.poiDataIndex].reference || this.tableData[this.poiDataIndex].reference === '-') {
          this.poiRequest(this.tableData[this.poiDataIndex], this.poiDataIndex)
        } else {
          this.getPOI()
        }
      }
    },
    geoRequest (data, index) {
      this.requestCountGeoData++
      if (this.requestCountGeoData < 10) {
        this.getGeoData()
      }
      geoReferenceApi.getGeoData(data.lat, data.long, this.maxRadius).then(response => {
        this.requestCountGeoData--
        if (this.requestCountGeoData < 10) {
          this.getGeoData()
        }
        const updatedItem = this.tableData[index]
        updatedItem.address = response.data.geoLocationData.title + ' - ' + response.data.geoLocationData.subtitle
        this.tableData.splice(index, 1, updatedItem)
      }).catch(() => {
        this.requestCountGeoData--
        if (this.requestCountGeoData < 10) {
          this.getGeoData()
        }
      })
    },
    poiRequest (data, index) {
      this.requestCountPOI++
      if (this.requestCountPOI < 10) {
        this.getPOI()
      }
      geoReferenceApi.getPOI(data.lat, data.long, data.thingId, data.enterpriseId, data.activityId).then(response => {
        this.requestCountPOI--
        if (this.requestCountPOI < 10) {
          this.getPOI()
        }
        const updatedItem = this.tableData[index]
        updatedItem.reference = response.data ? response.data.name : undefined
        this.tableData.splice(index, 1, updatedItem)
      }).catch(() => {
        this.requestCountPOI--
        if (this.requestCountPOI < 10) {
          this.getPOI()
        }
      })
    }
  },
  watch: {
    selectedItem () {
      if (this.selectedItem) {
        this.getData(this.selectedItem)
      }
    },
    /*
      [EVALUAR SI CORRESPONDE LA APLICACIÓN DE ESTA VERIFICACIÓN]
      Si hay 10 requests activas, activo un temporizador. Si se cumple el tiempo, entonces cancelo
      las requests activas y continuo buscando.
      Este "reseteo" se emplea para evitar que el proceso se quede congelado en requests de las cuales no se obtiene
      una respuesta.
      Cuando hay menos de 10, el proceso continua sin la activación de temporizadores.
    */
    requestCountGeoData () {
      const that = this
      if (this.requestCountGeoData === 10 && that.geoDataIndex < that.tableData.length) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelGeoData('cancel geo-data requests')
          that.requestCountGeoData = 0
          that.getGeoData()
        }, 60000)
      } else if (that.tableData.filter(a => !a.ubication).length > 0) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelGeoData('cancel geo-data requests')
          that.geoDataIndex = -1
          that.requestCountGeoData = 0
          that.getGeoData()
        }, 60000)
      } else {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
      }
    },
    requestCountPOI () {
      const that = this
      if (this.requestCountPOI === 10 && that.poiDataIndex < that.tableData.length) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelPOI('cancel poi requests')
          that.requestCountPOI = 0
          that.getPOI()
        }, 60000)
      } else if (that.tableData.filter(a => !a.reference).length > 0) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelPOI('cancel poi requests')
          that.poiDataIndex = -1
          that.requestCountPOI = 0
          that.getPOI()
        }, 60000)
      } else {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
      }
    }
  }
}
