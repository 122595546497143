import Vue from 'vue'
import i18n from '@/i18n'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import runningHoursReportApi from '@/api/runningHoursReport.api'
import reportStorageApi from '@/api/report-storage.api'
import { filterRulesMapped } from '@/tools/filterRules'
import thingApi from '../../api/thing.api'
import eventApi from '../../api/event.api'
import sectorApi from '../../api/sector.api'
import { SnackbarStyle } from '@/constants/constants'
import { getChildrenFromList, getDateTimeRange, selectorDateTimeValidation, ISODateFromUnixTimestamp } from '@/tools/functions'
import moment from 'moment'
import { ReportConversionUtil, TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import RunningHoursDetailReportComponent from './detail/RunningHoursDetailReport.vue'
import { configurationService } from '@/business/configurationService'
import service from '@/middleware'
import selectorService from '@/business/selectorService'
import runningHoursReportService from '../../business/runningHoursReportService'

export default {
  name: 'RunningHoursReportComponent',
  components: {
    SelectorComponent,
    BreadcrumbComponent,
    RunningHoursDetailReportComponent
  },
  props: {
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'harvesterId',
    pieChartConfig: [],
    barChartConfig: [],
    showSelector: false,
    chartDetails: '',
    loadingDetails: true,
    selectorModel: selectorService.getSelectorModel(false),
    sectorFilter: {
      id: 'sector',
      name: i18n.t('headers.sector'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    workFrontFilter: {
      id: 'workFront',
      name: i18n.t('headers.harvestFront'),
      show: false,
      disabled: true,
      showDialog: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    eventFilter: {
      id: 'event',
      name: i18n.t('headers.event'),
      show: false,
      disabled: false,
      showDialog: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    filterSwitch: true,
    timeFormat: null,
    intervalId: null,
    sectors: [],
    thingsId: [],
    things: [],
    eventsId: [],
    events: [],
    dateAndTimeRange: TimeRanges.LAST_HOUR,
    dateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    timeStampFrom: moment().unix(),
    timeStampTo: moment().subtract(1, 'hours').unix(),
    autoReport: false,
    autoReportMinutes: 10,
    loadingSpinner: true,
    settingDisabled: false,
    loadingTable: false,
    disableRefreshTable: true,
    tabsRunningHours: [
      {
        id: 'tab-running-hours',
        name: i18n.t('dashboard.runningHoursReport')
      }
    ],
    selectedTabRunningHours: 'tab-running-hours',
    breadcrumbButtomsRunningHours: [],
    chartComponentModel: {
      showRangeSelector: false,
      rangeSelectorData: []
    },
    tableConfig: {},
    middleware: service.http,
    reportNameForChart: [{ id: 'reportName', name: `<b>${i18n.t('dashboard.runningHoursReport')}</b>` }]
  }),
  computed: {
    ...mapGetters([
      'getFirebaseToken'
    ]),
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  created () {
    this.breadcrumbButtomsRunningHours.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: this.showSelectorButton.bind(this),
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // cargo la configuración de la tabla
    await this.getConfig()
    // Inicializo los datos del selector
    await this.initializeSelector()
    const reportKey = this.$route.query.key
    this.getData(reportKey)
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  methods: {
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    /**
     * Inicializador de los datos del selector
     */
    async initializeSelector () {
      this.selectorModel.filters.push(this.sectorFilter, this.workFrontFilter, this.eventFilter)

      // callback cuando se seleccionan items en los filtros
      this.sectorFilter.selectAction = this.sectorFilterCallback.bind(this)
      // datos para el selector de sectores
      const sectorResponse = await sectorApi.getAll()
      this.sectorFilter.data.cleanAndUpdate(sectorResponse.data)
      if (sectorResponse.data.length === 1 && sectorResponse.data[0].key === 'NO_SECTOR') {
        this.sectorFilter.selectedData = this.sectorFilter.data.filter(s => s.key === 'NO_SECTOR')
        this.sectorFilter.disabled = true
        this.sectorFilter.hide = true
        this.sectorFilterCallback()
      }
      const eventsResponse = await eventApi.getEventsFilterStructure()
      this.eventFilter.data.cleanAndUpdate(eventsResponse.data)
    },
    async sectorFilterCallback () {
      if (this.sectorFilter.selectedData.length > 0) {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = false
        // datos para el selector de cosas
        const response = await thingApi.getWorkFrontsNested(this.sectorFilter.selectedData[0].key)
        this.workFrontFilter.data.cleanAndUpdate(response.data)
      } else {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
      }
    },
    getData (reportKey) {
      if (reportKey) {
        this.showSelector = false
        this.loadingTable = true
        this.disableRefreshTable = true
        const pieChartRef = this.$refs['runningHoursPieChart']
        const barChartRef = this.$refs['runningHoursBarChart']
        reportStorageApi.getReport(reportKey).then(async response => {
          const { data, filters } = response.data
          const processedData = await runningHoursReportService.processSummaryData(data, filters)
          this.tableHeaders.cleanAndUpdate(processedData.headers)
          this.setTableFilterRules(processedData.headers)
          this.tableData.cleanAndUpdate(processedData.data)
          this.updateChart(this.pieChartConfig, [processedData.pieChart], pieChartRef, true)
          this.updateChart(this.barChartConfig, [processedData.barChart, processedData.barSectorChart, processedData.barHarvestFrontChart], barChartRef)
          this.setFilterData(processedData.filters)
          this.settingDisabled = false
          this.loadingTable = false
        })
      } else {
        this.showSelector = true
      }
    },
    updateChart (config, newCharts, ref, formatTooltip = false) {
      if (formatTooltip) {
        newCharts.forEach(c => {
          c.options.tooltips = {
            callbacks: {
              label (tooltipItem, data) {
                let index = tooltipItem.index
                let label = ''
                if (data.datasets && data.datasets.length > 0 && (data.datasets[0].data.length >= index) && (data.labels.length >= index)) {
                  label = data.labels[index] + ': ' + ReportConversionUtil.secondsToStringFormatted(data.datasets[0].data[index], this.timeFormat.value)
                }
                return label
              }
            }
          }
        })
      }
      config.cleanAndUpdate(newCharts)
      if (ref) {
        newCharts.forEach(c => {
          ref.reset(c)
        })
      }
    },
    selector () {
      this.showSelector = !this.showSelector
    },
    showSelectorButton () {
      return true
    },
    disableSelectorButton () {
      return this.settingDisabled
    },
    getReport (eventData) {
      this.filterSwitch = eventData.filterSwitchValue
      this.things = []
      if (eventData.filterSwitchValue) {
        if (this.sectorFilter.hide) {
          this.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
        } else {
          this.sectors.clean()
        }
        getChildrenFromList(eventData.groupEquipmentFilterSelected, this.things)
        this.events.clean()
        this.eventsId.clean()
      } else {
        this.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
        getChildrenFromList(this.workFrontFilter.selectedData, this.things)
        this.events = this.eventFilter.selectedData
        this.eventsId = this.events.map(e => e.id)
      }
      this.thingsId = this.things.map(t => t.id)

      this.dateAndTimeRange = eventData.dateAndTimeRange
      this.dateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType
      this.sinceDate = eventData.sinceDate
      this.sinceTime = eventData.sinceTime
      this.toDate = eventData.toDate
      this.toTime = eventData.toTime
      const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
        eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      this.timeFormat = eventData.dateAndTimeFormat

      this.autoReport = eventData.autoReport
      this.autoReportMinutes = eventData.autoReportMinutes

      const that = this
      const pieChartRef = this.$refs['runningHoursPieChart']
      const barChartRef = this.$refs['runningHoursBarChart']
      if (eventData.autoReport) {
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        that.loadingTable = true
        that.disableRefreshTable = false
        const filters = {
          things: that.thingsId,
          from: that.timeStampFrom,
          to: that.timeStampTo,
          events: that.eventsId,
          sector: that.sectors,
          timeFormat: that.timeFormat
        }
        runningHoursReportApi.getReportDirect(that.thingsId, that.timeStampFrom, that.timeStampTo, that.eventsId, that.sectors, that.timeFormat)
          .then(async response => {
            const processedData = await runningHoursReportService.processSummaryData(response.data, filters)
            that.tableHeaders.cleanAndUpdate(processedData.headers)
            that.setTableFilterRules(processedData.headers)
            that.tableData.cleanAndUpdate(processedData.data)
            that.updateChart(that.pieChartConfig, [processedData.pieChart], pieChartRef, true)
            that.updateChart(that.barChartConfig, [processedData.barChart, processedData.barSectorChart, processedData.barHarvestFrontChart], barChartRef)
            if (that.loadingSpinner) that.commitVisible({ visible: false })
            that.loadingTable = false
            that.setReportDetails()
          })
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            const conversion = getDateTimeRange(that.dateAndTimeRange, that.dateAndTimeRangeCustomType,
              that.sinceDate, that.sinceTime, that.toDate, that.toTime)
            that.timeStampFrom = conversion.tFrom
            that.timeStampTo = conversion.tTo
            if (that.loadingSpinner) that.commitVisible({ visible: true })
            that.loadingTable = true
            runningHoursReportApi.getReportDirect(that.thingsId, that.timeStampFrom, that.timeStampTo, that.eventsId, that.sectors, that.timeFormat)
              .then(async response => {
                const processedData = await runningHoursReportService.processSummaryData(response.data, filters)
                that.tableData.cleanAndUpdate(processedData.data)
                that.updateChart(that.pieChartConfig, [processedData.pieChart], pieChartRef, true)
                that.updateChart(that.barChartConfig, [processedData.barChart, processedData.barSectorChart, processedData.barHarvestFrontChart], barChartRef)
                if (that.loadingSpinner) that.commitVisible({ visible: false })
                that.loadingTable = false
              })
          },
          eventData.autoReportMinutes * 60 * 1000)
      } else {
        runningHoursReportService.getRunningHoursReportDeferred(this.thingsId, this.things, this.timeStampFrom,
          this.timeStampTo, this.eventsId, this.events, this.dateAndTimeRange,
          this.dateAndTimeRangeCustomType, this.sinceDate, this.sinceTime, this.toDate, this.toTime, this.sectors, this.$route.path, this.timeFormat)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }
      this.showSelector = false
    },
    setReportDetails () {
      this.loadingDetails = true
      const detailData = {
        from: this.timeStampFrom,
        to: this.timeStampTo,
        sector: this.sectors,
        things: this.things,
        events: this.events,
        reportName: this.reportNameForChart
      }
      const dataStructure = [
        { detailDataProp: 'reportName', propTitle: this.$t('report'), propType: 'LIST', propRefs: { list: this.reportNameForChart, value: 'id', text: 'name' } },
        { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
        { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' },
        { detailDataProp: 'sector', propTitle: this.$t('headers.sector'), propType: 'LIST', propRefs: { list: this.sectors, value: 'id', text: 'name' } },
        { detailDataProp: 'things', propTitle: this.$t('things'), propType: 'LIST', propRefs: { list: this.things, value: 'id', text: 'name' } },
        { detailDataProp: 'events', propTitle: this.$t('events'), propType: 'LIST', propRefs: { list: this.events, value: 'id', text: 'name' } }
      ]
      this.setDetails({
        detailData,
        dataStructure
      })
      this.chartDetails = this.getDetails
      this.loadingDetails = false
    },
    resize () {
      this.$eventBus.$emit('resizeCharts')
    },
    clickRow (data) {
      this.setEntityConfiguration({ name: this.$t('harvesters.title'), value: 'thingId', text: 'thingName' })
      const items = []
      this.tableData.forEach(thing => {
        items.push({
          thingId: thing.thingId,
          thingName: thing.thingName,
          from: this.timeStampFrom,
          to: this.timeStampTo,
          events: this.eventsId,
          autoReport: this.autoReport,
          autoReportMinutes: this.autoReportMinutes,
          dateTimeRange: this.dateAndTimeRange,
          customDateTimeRangeType: this.dateAndTimeRangeCustomType
        })
      })
      this.setEntitySelectorItems(items)
      this.setSelectedItem({
        thingId: data.thingId,
        thingName: data.thingName,
        from: this.timeStampFrom,
        to: this.timeStampTo,
        events: this.eventsId,
        autoReport: this.autoReport,
        autoReportMinutes: this.autoReportMinutes,
        dateTimeRange: this.dateAndTimeRange,
        customDateTimeRangeType: this.dateAndTimeRangeCustomType
      })
      if (this.tabsRunningHours.filter(tab => tab.id === 'tab-running-hours-detail').length === 0) {
        this.tabsRunningHours.push({
          id: 'tab-running-hours-detail',
          name: i18n.t('detail')
        })
      }
      this.selectedTabRunningHours = 'tab-running-hours-detail'
    },
    refreshTable () {
      const pieChartRef = this.$refs['runningHoursPieChart']
      const barChartRef = this.$refs['runningHoursBarChart']
      const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
        this.sinceDate, this.sinceTime, this.toDate, this.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      if (this.loadingSpinner) this.commitVisible({ visible: true })
      this.loadingTable = true
      runningHoursReportApi.getReportDirect(this.thingsId, this.timeStampFrom, this.timeStampTo, this.eventsId, this.sectors, this.timeFormat)
        .then(async response => {
          const filters = {
            things: this.thingsId,
            from: this.timeStampFrom,
            to: this.timeStampTo,
            events: this.eventsId,
            sector: this.sectors,
            timeFormat: this.timeFormat
          }
          const processedData = await runningHoursReportService.processSummaryData(response.data, filters)
          this.tableData.cleanAndUpdate(processedData.data)
          this.updateChart(this.pieChartConfig, [processedData.pieChart], pieChartRef, true)
          this.updateChart(this.barChartConfig, [processedData.barChart, processedData.barSectorChart, processedData.barHarvestFrontChart], barChartRef)
          if (this.loadingSpinner) this.commitVisible({ visible: false })
          this.loadingTable = false
        })
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    /**
     * Inicializar los valores de los selctores con los datos guardados en el reporte
     * @param {*} filterData
     */
    async setFilterData (filterData) {
      if (filterData) {
        // switch de tipo de filtrado
        this.selectorModel.filterSwitch.value = filterData.filterSwitch
        this.filterSwitch = filterData.filterSwitch

        // Filtro de sectores
        if (this.sectorFilter.data.length > 0 && filterData.sector && filterData.sector.length > 0) {
          this.sectors.cleanAndUpdate(filterData.sector)
          this.sectorFilter.selectedData.cleanAndUpdate(filterData.sector)
          await this.sectorFilterCallback()
        }

        // Filtro de frentes/equipos
        this.thingsId = filterData.things
        this.things = filterData.thingsData
        if (this.filterSwitch) {
          this.things.forEach(thing => {
            if (this.selectorModel.groupEquipmentFilter.data.find(t => (t.id === thing.id || (t.children && t.children.length > 0 && t.children.find(child => (child.id === thing.id)))) && !this.selectorModel.groupEquipmentFilter.selectedData.find(t => t.id === thing.id))) {
              this.selectorModel.groupEquipmentFilter.selectedData.push(thing)
            }
          })
        } else {
          this.things.forEach(thing => {
            if (this.workFrontFilter.data.find(t => (t.id === thing.id || (t.children && t.children.length > 0 && t.children.find(child => (child.id === thing.id)))) && !this.workFrontFilter.selectedData.find(t => t.id === thing.id))) {
              this.workFrontFilter.selectedData.push(thing)
            }
          })
        }

        // Filtro de eventos
        if (this.eventFilter.data.length > 0 && filterData.eventsData && filterData.eventsData.length > 0) {
          this.eventsId = filterData.events
          this.events = filterData.eventsData
          this.eventFilter.selectedData.cleanAndUpdate(this.events)
        }

        // rango de tiempo
        this.timeStampFrom = filterData.from
        this.timeStampTo = filterData.to
        this.selectorModel.selectedDateAndTimeRange = filterData.dateTimeRange
        this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.customDateTimeRangeType
        this.selectorModel.sinceDate = filterData.sinceDate
        this.selectorModel.sinceTime = filterData.sinceTime
        this.selectorModel.toDate = filterData.toDate
        this.selectorModel.toTime = filterData.toTime
        this.selectorModel.selectedTimeFormat = filterData.timeFormat
        this.timeFormat = filterData.timeFormat
        this.dateAndTimeRange = filterData.dateTimeRange
        this.dateAndTimeRangeCustomType = filterData.customDateTimeRangeType
        this.sinceDate = filterData.sinceDate
        this.sinceTime = filterData.sinceTime
        this.toDate = filterData.toDate
        this.toTime = filterData.toTime

        this.setCustomDateAndTimeFilter(filterData)
        this.setReportDetails()
      }
    },
    setCustomDateAndTimeFilter (filterData) {
      if (filterData.from && filterData.to) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from, true)
        const toDateData = ISODateFromUnixTimestamp(filterData.to, true)

        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        } else if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM &&
          this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
          this.selectorModel.sinceTime = null
          this.selectorModel.toTime = null
        } else {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const thingsSelected = that.selectorModel.showGroupEquipmentFilter && that.selectorModel.filterSwitch.value
          ? that.selectorModel.groupEquipmentFilter.selectedData
          : that.workFrontFilter.selectedData
        const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
          that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
          that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)
        const timeFormatSelected = that.selectorModel.selectedTimeFormat
        that.selectorModel.disableGetReportButton = thingsSelected.length === 0 || customTimeValidation || !timeFormatSelected
      })
    },
    tabSelectedRunningHours (id) {
      // Se resetea el breadcrumb al volver al Resumen
      if (id === 'tab-running-hours') {
        this.tabsRunningHours = [
          {
            id: 'tab-running-hours',
            name: i18n.t('dashboard.runningHoursReport')
          }
        ]

        if (this.$store.state.app.entitySelectorItems && this.$store.state.app.entitySelectorItems.length > 0) {
          this.$store.state.app.entitySelectorItems = []
        }
      }
      this.selectedTabRunningHours = id
    },
    /**
     * Para cargar la configuración de la tabla
     */
    async getConfig () {
      const config = await configurationService.get('running-hours-report')
      this.tableConfig = config && config.data ? config.data : {}
    },
    /**
     * Para guardar la configuración de la tabla
     * @param {*} config
     */
    saveConfig (config) {
      configurationService.save('running-hours-report', config)
        .then(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
          this.getConfig()
        }).catch(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
        })
    }
  },
  watch: {
    selectorModel: {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
