import service from '@/middleware'

const runningHoursReportApi = {
  async getReport (things, from, to, events, route, filters) {
    const body = {
      reportQueryFilters: {
        things,
        events,
        from,
        to,
        route
      },
      filters
    }
    const result = await service.http.post('/api/running-hours/summary-report', body)
    return result
  },

  async getDetail (id, from, to, events) {
    const body = {
      id,
      from,
      to,
      events
    }
    return service.http.post('/api/running-hours/detail-report', body)
  },

  async getReportDirect (things, from, to, events, sector, timeFormat) {
    const body = {
      things,
      from,
      to,
      events,
      sector,
      timeFormat
    }
    return service.http.post('/api/running-hours/summary-report/direct', body)
  }
}

export default runningHoursReportApi
