/* eslint-disable no-unused-vars */
import service from '@/middleware'
import axios from 'axios'

const CancelToken = axios.CancelToken
export let cancelGeoData
export let cancelPOI

export const geoReferenceApi = {
  async getGeoData (lat, long, radius) {
    return service.http.get(
      `/api/georeference/geo-data?lat=${lat}&long=${long}&radius=${radius}`,
      { cancelToken: new CancelToken(function executor (c) { cancelGeoData = c }) })
  },
  async getPOI (lat, long, trackId, enterpriseId, activityId) {
    return service.http.get(
      `/api/georeference/poi?lat=${lat}&long=${long}&vehicle=${trackId}&enterprise=${enterpriseId}&activity=${activityId}`,
      { cancelToken: new CancelToken(function executor (c) { cancelPOI = c }) })
  }
}
