import runningHoursReportApi from '@/api/runningHoursReport.api'
import { getTimeOffset } from '@/tools/functions'
import i18n from '@/i18n'
import conversionUtil from '@/utils/conversion.util'
import reportsUtil from '@/utils/reports.util'
import { ReportConversionUtil, Util, ValueText } from '@colven/common-domain-lib/lib'
import RunningHoursConstants from '@/constants/runningHoursConstants'

async function getRunningHoursReportDeferred (things, thingsData, from, to, events, eventsData, dateTimeRange,
  customDateTimeRangeType, sinceDate, sinceTime, toDate, toTime, sector, route, timeFormat) {
  const filters = {
    things,
    thingsData,
    from,
    to,
    events,
    eventsData,
    dateTimeRange,
    customDateTimeRangeType,
    sinceDate,
    sinceTime,
    toDate,
    toTime,
    sector,
    route,
    timeFormat
  }
  try {
    await runningHoursReportApi.getReport(things, from, to, events, route, filters)
  } catch (error) {
    console.error(error)
  }
  return null
}

const processSummaryDataDetail = async data => {
  const timeOffset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  const report = {}
  const table = await processDataDetail(data, timeOffset, lang)
  report.headers = RunningHoursConstants.headersDetail(lang, reportsUtil)
  report.data = table
  return report
}

const processSummaryData = async (data, filters) => {
  const offset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  const report = {}
  const { table, runningMoving, runningStopped, stopped, datasetData, chartLabels, sectorChart, harvestFrontChart } =
    await processData(data.report, lang, filters.sector[0].name, filters.timeFormat.value)
  report.headers = RunningHoursConstants.headers(lang, data.eventMap, reportsUtil)
  report.data = table
  report.pieChart = RunningHoursConstants.totalChart(filters.from, filters.to, lang, offset, runningMoving, runningStopped, stopped, filters.timeFormat.value)
  report.barChart = RunningHoursConstants.consumptionChart(
    filters.from,
    filters.to,
    lang,
    offset,
    datasetData,
    chartLabels,
    i18n.t('runningHoursReport.CHARTS.consumptionChart', { lang }),
    i18n.t('runningHoursReport.CHARTS.vehicleLabel', { lang }),
    'chartConsumptionVehicle'
  )
  report.barSectorChart = RunningHoursConstants.consumptionChart(
    filters.from,
    filters.to,
    lang,
    offset,
    Object.values(sectorChart),
    Object.keys(sectorChart),
    i18n.t('runningHoursReport.CHARTS.consumptionSectorChart', { lang }),
    i18n.t('runningHoursReport.CHARTS.sectorLabel', { lang }),
    'chartConsumptionSector'
  )
  report.barHarvestFrontChart = RunningHoursConstants.consumptionChart(
    filters.from,
    filters.to,
    lang,
    offset,
    Object.values(harvestFrontChart),
    Object.keys(harvestFrontChart),
    i18n.t('runningHoursReport.CHARTS.consumptionHarvestFrontChart', { lang }),
    i18n.t('runningHoursReport.CHARTS.harvestFrontLabel', { lang }),
    'chartConsumptionHF'
  )
  report.filters = filters
  report.barChart.chartComponentId = 'equipmentConsumption'
  report.barSectorChart.chartComponentId = 'sectorConsumption'
  report.barHarvestFrontChart.chartComponentId = 'workFrontConsumption'
  return report
}

const processData = async (data, lang, sectorName, timeFormat) => {
  const table = []
  let runningMoving = 0
  let runningStopped = 0
  let stopped = 0
  let horomerterInitial = 0
  let horomerterFinal = 0
  let odometerInitial = 0
  let odometerFinal = 0
  const datasetData = []
  const chartLabels = []
  const sectorChart = {}
  sectorChart[sectorName] = 0
  const harvestFrontChart = {}

  data.forEach(d => {
    const row = {
      harvestFrontName: d.thingData.harvestFrontName,
      thingId: d.thingId,
      thingName: d.thingData.name,
      runningTotal: { value: d.runningTotalSeconds, text: ReportConversionUtil.secondsToStringFormatted(d.runningTotalSeconds, timeFormat) },
      runningMoving: { value: d.runningMovingSeconds, text: ReportConversionUtil.secondsToStringFormatted(d.runningMovingSeconds, timeFormat) },
      runningStopped: { value: d.runningStoppedSeconds, text: ReportConversionUtil.secondsToStringFormatted(d.runningStoppedSeconds, timeFormat) },
      stopped: { value: d.stoppedSeconds, text: ReportConversionUtil.secondsToStringFormatted(d.stoppedSeconds, timeFormat) },
      horomerterInitial: Util.roundDecimals(d.horometroInitial),
      horomerterFinal: Util.roundDecimals(d.horometroFinal),
      odometerInitial: Util.roundDecimals(d.odometroInitial),
      odometerFinal: Util.roundDecimals(d.odometroFinal),
      configuredConsumption: conversionUtil.consumptionToStringTranslated(lang, d.thingData.consumption),
      consumption: { value: d.consumption, text: Util.roundDecimals(d.consumption, 2) + ' lt' },
      expense: d.expense.toFixed(2)
    }
    Object.assign(row, d.eventCount)
    table.push(row)

    runningMoving += d.runningMovingSeconds
    runningStopped += d.runningStoppedSeconds
    stopped += d.stoppedSeconds

    datasetData.push(parseFloat(d.consumption.toFixed(2)))
    chartLabels.push(d.thingData.name)
    sectorChart[sectorName] += parseFloat(d.consumption.toFixed(2))
    if (!harvestFrontChart[d.thingData.harvestFrontName]) {
      harvestFrontChart[d.thingData.harvestFrontName] = 0
    }
    harvestFrontChart[d.thingData.harvestFrontName] += parseFloat(d.consumption.toFixed(2))
  })
  return { table, runningMoving, runningStopped, stopped, horomerterInitial, horomerterFinal, odometerInitial, odometerFinal, datasetData, chartLabels, sectorChart, harvestFrontChart }
}

const processDataDetail = async (data, timeOffset, lang) => {
  const table = []
  data.forEach(d => {
    const { dateString, timeString } = ReportConversionUtil.applyTimezone(d.trackTimestamp, timeOffset)
    table.push({
      lat: d.lat,
      long: d.long,
      thingName: d.thingInfo.name,
      eventName: (d.eventInfo && d.eventInfo.name) ? d.eventInfo.name[lang] : undefined,
      date: dateString + ' ' + timeString,
      address: null,
      driveUnitName: d.driveUnitsInfo ? d.driveUnitsInfo.properties.Name : undefined,
      reference: undefined,
      speed: new ValueText(d.speed, ReportConversionUtil.speedToString(d.speed, d.direction))
    })
  })
  return table
}

export default {
  getRunningHoursReportDeferred,
  processSummaryData,
  processSummaryDataDetail
}
