import { FilterType, DataType, Axes, Options, Chart, Dataset, ChartType, Util, ReportConversionUtil } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const headers = (lang, events, reportsUtil) => {
  const eventHeaders = []
  Object.values(events).forEach(e => {
    eventHeaders.push({
      text: e.name[lang],
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: e._id,
      width: reportsUtil.calculateColumnWidth(e.name[lang])
    })
  })
  return [
    {
      text: i18n.t('runningHoursReport.HEADERS.HARVEST_FRONT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'harvestFrontName',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.HARVEST_FRONT', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.VEHICLE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'thingName',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.HARVEST_FRONT', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.RUNNING_TOTAL', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'runningTotal',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.RUNNING_TOTAL', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.RUNNING_MOVING', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'runningMoving',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.RUNNING_MOVING', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.RUNNING_STOPPED', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'runningStopped',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.RUNNING_STOPPED', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.STOPPED', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'stopped',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.STOPPED', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.HOROMETER_INITIAL', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'horomerterInitial',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.HOROMETER_INITIAL', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.HOROMETER_FINAL', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'horomerterFinal',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.HOROMETER_FINAL', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.ODOMETER_INITIAL', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'odometerInitial',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.ODOMETER_INITIAL', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.ODOMETER_FINAL', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'odometerFinal',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.ODOMETER_FINAL', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.CONFIGURED_CONSUMPTION', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'configuredConsumption',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.CONFIGURED_CONSUMPTION', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.CONSUMPTION', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'consumption',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.CONSUMPTION', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.EXPENSE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'expense',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.EXPENSE', { lang }))
    }
  ].concat(eventHeaders)
}

const headersDetail = (lang, reportsUtil) => {
  return [
    {
      text: i18n.t('runningHoursReport.HEADERS.VEHICLE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'thingName',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.VEHICLE', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.EVENT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'eventName',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.EVENT', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.DATE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'date',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.DATE', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.ADDRESS', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'address',
      // spinner: true,
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.ADDRESS', { lang }))
      // cellConfig: {
      //     spinnerColor: '#03A9F4',
      // },
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.DRIVE_UNIT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'driveUnitName',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.DRIVE_UNIT', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.REFERENCE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'reference',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.REFERENCE', { lang }))
    },
    {
      text: i18n.t('runningHoursReport.HEADERS.SPEED', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'speed',
      width: reportsUtil.calculateColumnWidth(i18n.t('runningHoursReport.HEADERS.SPEED', { lang }))
    }
  ]
}

const totalChart = (from, to, lang, offset, runningMoving, runningStopped, stopped, timeFormat) => {
  const yAxes = new Axes(ChartType.PIE)
  const xAxes = new Axes(ChartType.PIE)
  const options = new Options()
  options.tooltips.mode = 'point'
  const chart = new Chart()
  const dataset = new Dataset()
  // id
  chart.id = `chartTotal`
  // nombre
  chart.name = i18n.t('runningHoursReport.CHARTS.totalChart', { lang })
  // tipo
  chart.type = ChartType.PIE
  // labels
  const total = runningMoving + runningStopped + stopped
  const percentageRunningMoving = Util.roundDecimals((runningMoving / (total !== 0 ? total : 1)) * 100)
  const percentageRunningStopped = Util.roundDecimals((runningStopped / (total !== 0 ? total : 1)) * 100)
  const percentageStopped = Util.roundDecimals((stopped / (total !== 0 ? total : 1)) * 100)
  const labelRunningMoving = i18n.t('runningHoursReport.HEADERS.RUNNING_MOVING',
    { lang }) + ' (' + percentageRunningMoving + '%)'
  const labelRunningStopped = i18n.t('runningHoursReport.HEADERS.RUNNING_STOPPED',
    { lang }) + ' (' + percentageRunningStopped + '%)'
  const labelStopped = i18n.t('runningHoursReport.HEADERS.STOPPED',
    { lang }) + ' (' + percentageStopped + '%)'
  chart.data.labels = [labelRunningMoving, labelRunningStopped, labelStopped]
  // Dataset
  dataset.data = [runningMoving, runningStopped, stopped]
  dataset.formattedTooltipData.label = [
    ReportConversionUtil.secondsToStringFormatted(runningMoving, timeFormat),
    ReportConversionUtil.secondsToStringFormatted(runningStopped, timeFormat),
    ReportConversionUtil.secondsToStringFormatted(stopped, timeFormat)
  ]
  dataset.formattedLabelData = [
    ReportConversionUtil.secondsToStringFormatted(runningMoving, timeFormat),
    ReportConversionUtil.secondsToStringFormatted(runningStopped, timeFormat),
    ReportConversionUtil.secondsToStringFormatted(stopped, timeFormat)
  ]
  dataset.label = ''
  yAxes.scaleLabel.labelString = ''
  // Color
  let pieColor

  dataset.backgroundColor = []
  for (let i = 0; i <= dataset.data.length - 1; i++) {
    pieColor = Util.pickColor(dataset.backgroundColor)
    dataset.backgroundColor.push(pieColor)
  }

  dataset.borderWidth = 0
  // Agrego el dataset creado
  chart.data.datasets.push(dataset)
  // Options
  const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
  const toConversion = ReportConversionUtil.applyTimezone(to, offset)
  const title = fromConversion.dateString + ' ' + fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
  options.title.text = title
  xAxes.scaleLabel.labelString = ''
  options.scales.yAxes.push(yAxes)
  options.scales.xAxes.push(xAxes)

  chart.options = options
  return chart
}

const consumptionChart = (from, to, lang, offset, data, labels, name, xlabel, id) => {
  const yAxes = new Axes(ChartType.BAR)
  const xAxes = new Axes(ChartType.BAR)
  const options = new Options()
  const chart = new Chart()
  const dataset = new Dataset()
  // id
  chart.id = id
  // nombre
  chart.name = name
  // tipo
  chart.type = ChartType.BAR

  // Dataset
  dataset.data = data
  dataset.label = i18n.t('runningHoursReport.HEADERS.CONSUMPTION', { lang })
  chart.data.labels = labels

  yAxes.scaleLabel.labelString = i18n.t('runningHoursReport.CHARTS.litresLabel', { lang })
  xAxes.scaleLabel.labelString = xlabel
  data.forEach((item) => {
    dataset.formattedTooltipData.label.push(item + ' lt')
    dataset.formattedLabelData.push(item + ' lt')
  })
  // Color
  let pieColor
  dataset.backgroundColor = []
  pieColor = Util.pickColor(dataset.backgroundColor)
  for (let i = 0; i <= dataset.data.length - 1; i++) {
    dataset.backgroundColor.push(pieColor)
  }

  dataset.borderWidth = 0

  // Agrego el dataset creado
  chart.data.datasets.push(dataset)
  // Options
  const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
  const toConversion = ReportConversionUtil.applyTimezone(to, offset)
  const title = fromConversion.dateString + ' ' + fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
  options.title.text = title
  // xAxes.scaleLabel.labelString = 'harvesters.reports.harvestArea.farm'
  options.scales.yAxes.push(yAxes)
  options.scales.xAxes.push(xAxes)
  chart.options = options
  return chart
}

export default {
  headers,
  headersDetail,
  totalChart,
  consumptionChart
}
